@font-face {
  font-family: "Flama";
  src: url("./assets/fonts/flama-regular.otf");
}

@font-face {
  font-family: "FlamaBold";
  src: url("./assets/fonts/flama-bold.otf");
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
i {
  font-family: "Flama";
  font-weight: 500;
}

b {
  font-family: "FlamaBold";
}